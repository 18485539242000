<template>
  <div class="sidebar" id="sidebar">
    <div class="site-name" data-path="/" @click="_jump">{{ systemConfig.short_name }}</div>

    <template v-if="toolsRoutes1.length">
      <ul class="main-nav">
        <li v-for="(item,index) in toolsRoutes1" :key="index">
          <router-link :to="{name:item.name}" v-if="item.meta.classify!=='home'">{{ item.meta.title }}</router-link>
        </li>
      </ul>
    </template>

    <template v-if="teamRoutes.length">
      <div class="hr"></div>
      <ul class="main-nav">
        <li v-for="(item,index) in teamRoutes" :key="index">
          <router-link :to="{name:item.name}">{{ item.meta.title }}</router-link>
        </li>
      </ul>
    </template>

    <template v-if="userRoutes.length">
      <div class="hr"></div>
      <ul class="main-nav">
        <li v-for="(item,index) in userRoutes" :key="index">
          <router-link :to="{name:item.name}">{{ item.meta.title }}</router-link>
        </li>
      </ul>
    </template>

    <template v-if="providerRoutes.length">
      <div class="hr"></div>
      <ul class="main-nav">
        <li v-for="(item,index) in providerRoutes" :key="index">
          <router-link :to="{name:item.name}">{{ item.meta.title }}</router-link>
        </li>
      </ul>
    </template>

    <template v-if="toolsRoutes.length">
      <div class="hr"></div>
      <ul class="main-nav">
        <li v-for="(item,index) in toolsRoutes" :key="index">
          <router-link :to="{name:item.name}">{{ item.meta.title }}</router-link>
        </li>
      </ul>
    </template>

    <template v-if="appLicenceRoutes.length">
      <div class="hr"></div>
      <ul class="main-nav">
        <li v-for="(item,index) in appLicenceRoutes" :key="index">
          <router-link :to="{name:item.name}">{{ item.meta.title }}</router-link>
        </li>
      </ul>
    </template>

    <template v-if="systemRoutes.length">
      <div class="hr"></div>
      <ul class="main-nav">
        <li v-for="(item,index) in systemRoutes" :key="index">
          <router-link :to="{name:item.name}" v-if="userInfo.level <= item.meta.level">{{
              item.meta.title
            }}
          </router-link>
        </li>
      </ul>
    </template>
  </div>
</template>

<script>
import {appLicenceRoutes,systemRoutes, userRoutes,toolsRoutes, toolsRoutes1} from '../../router'
import {_data, _methods, util} from '../../common/public'

export default {
  name: 'PageSidebar',
  data() {
    return {
      ..._data,
      curClassify: "",
      routes: [],
      toolsRoutes1: [],
      pickUpOrderRoutes: [],
      dealerRoutes: [],
      teamRoutes: [],
      storageRoutes:[],
      financialRoutes: [],
      providerRoutes: [],
      userRoutes: [],
      referrerRoutes:[],
      appLicenceRoutes:[],
      toolsRoutes: [],
      systemRoutes: [],
      curRouteName: '',
      systemConfig: {},
      userInfo: {level: 1},
      auth: [],
      auth_classify: [],
      auth_array: [],
      auth_classify_array: [],
    }
  },
  created() {
    console.log('this.$route', this.$route)
    this.curRouteName = this.$route.name
    this.curClassify = this.$route.meta.classify

    this.systemConfig = util.getLocalStorage('systemConfig', {})
    this.userInfo = util.getLocalStorage('userInfo', {})
    console.log("this.userInfo--", this.userInfo, toolsRoutes1)
    this.processData()
  },
  methods: {
    ..._methods,
    _jump(e) {
      let {path} = e.target.dataset;
      this.$router.push({
        path: path
      })
    },
    processData() {
      this.auth = this.userInfo.auth ? JSON.parse(this.userInfo.auth) : []
      this.auth_classify = this.userInfo.auth_classify ? JSON.parse(this.userInfo.auth_classify) : []
      console.log('this.auth_classify', this.auth_classify)
      this.auth_array = []
      for (let item of this.auth) {
        this.auth_array.push(item.routeClient)
      }

      this.auth_classify_array = []
      for (let item of this.auth_classify) {
        this.auth_classify_array.push(item.alias)
      }

      this.toolsRoutes1 = this.moveItem(toolsRoutes1)
      this.userRoutes = this.moveItem(userRoutes)
      this.appLicenceRoutes = this.moveItem(appLicenceRoutes)
      this.toolsRoutes = this.moveItem(toolsRoutes)
      this.systemRoutes = this.moveItem(systemRoutes)
    },
    moveItem(arr) {
      if (this.userInfo.role - 0 < 3) {
        return arr
      }

      let _array = util.extend(true, [], arr)
      for (let i = _array.length - 1; i >= 0; i--) {
        if (_array[i].meta.classify === "home") {
          continue
        }
        let authClassify = _array[i].meta.authClassify || ''
        if (this.auth_classify_array.indexOf(authClassify) === -1) {
          _array.splice(i, 1)
        } else {
          if (_array[i].children.length) {
            for (let j = _array[i].children.length - 1; j >= 0; j--) {
              let authClassifyItem = _array[i].children[j].meta.authClassify || ''
              if (this.auth_classify_array.indexOf(authClassifyItem) === -1) {
                _array[i].children.splice(j, 1)
              }
            }
          }
        }
      }
      console.log('_array', _array)
      return _array
    }
  },
}
</script>

<style lang="scss" scoped>
.sidebar {
  width: 200px;
  background: #0365fa;
  color: #fff;
  font-size: 16px;
  overflow-y: auto;
}

.site-name {
  height: 70px;
  font-size: 26px;
  padding-left: 24px;
  margin-bottom: 8px;
  border-bottom: #2578f8 solid 1px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.sidebar {
  .sub-title {
    padding: 0 14px 10px;
    line-height: 1.5;
    border-left: #04d45a solid 2px;
    font-size: 18px;
  }

  .sub-title .small {
    font-size: 13px;
  }

  .hr {
    margin-top: 6px;
    margin-bottom: 6px;
    border-bottom: 1px solid #2578f8;
  }

  a {
    font-size: 16px;
    color: #fff;
    display: block;
    line-height: 38px;
    padding: 0 20px;
    border-left: transparent solid 4px;
    box-sizing: border-box;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: all 0.3s;

    &:hover {
      background: #2578f8;
    }
  }
}

.sidebar a.router-link-active {
  background: #2578f8;
  border-left-color: #04d45a;
}

.sidebar a.router-link-active:hover {
  background: #2578f8;
}

.selectYear {
  padding: 8px;
  margin-bottom: 8px;
}
</style>