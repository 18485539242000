import {createRouter, createWebHashHistory} from 'vue-router'
//import Layout from '@/layout'
import open from '@/layout/open'
import platform from '@/layout/platform'

const openRoutes = [
    {
        path: '/login',
        component: open,
        redirect: '/login',
        name: "login",
        meta: {title: '登录', classify: 'login', icon: 'login', affix: true, isShow: 0},
        children: [
            {
                path: '/login',
                component: () => import('@/views/login/index'),
                name: 'login',
                meta: {title: '登录', classify: 'login', icon: 'login', affix: true, isShow: 0}
            }
        ]
    },
    /*{
        path: '/',
        component:open,
        redirect: '/index',
        name: "index",
        meta: { title: '首页', classify: 'index', icon: 'index', affix: true, isShow: 0 },
        children: [
            {
                path: '/index',
                component: () => import('@/views/index/index'),
                name: 'indexIndex',
                meta: { title: '首页', classify: 'index', icon: 'index', affix: true, isShow: 0}
            }
        ]
    },*/
]

const toolsRoutes1 = filterByKey([
    {
        path: '/',
        component: platform,
        redirect: '/boss/index',
        name: "home",
        meta: {title: '首页', classify: 'home', icon: 'home', affix: true, level: 5, isShow: 1, authClassify: "home"},
        children: [
            {
                path: '/home',
                component: () => import('@/views/home/index'),
                name: 'homeIndex',
                meta: {
                    title: '首页',
                    classify: 'home',
                    icon: 'home',
                    affix: true,
                    level: 5,
                    isShow: 1,
                    authClassify: "home"
                }
            }
        ]
    },
    {
        path: '/nav',
        component: platform,
        redirect: '/nav/index',
        name: "nav",
        meta: {title: '导航管理', classify: 'nav', icon: 'nav', affix: true, level: 5, isShow: 0, authClassify: "nav"},
        children: [
            {
                path: 'index',
                component: () => import('@/views/nav/index'),
                name: 'navIndex',
                meta: {
                    title: '导航管理',
                    classify: 'nav',
                    icon: 'nav',
                    affix: true,
                    level: 4,
                    isShow: 1,
                    authClassify: "nav"
                }
            },
            {
                path: 'detail/:id',
                component: () => import('@/views/nav/detail.vue'),
                name: 'navDetail',
                meta: {
                    title: '导航详情',
                    classify: 'nav',
                    icon: 'nav',
                    affix: true,
                    level: 4,
                    isShow: 1,
                    authClassify: "nav"
                }
            },
        ]
    },
])

const userRoutes = filterByKey([
    {
        path: '/userGroup',
        component: platform,
        redirect: '/userGroup/index',
        name: "userGroup",
        meta: {
            title: '用户组管理',
            classify: 'userGroup',
            icon: 'userGroup',
            table: 'user_group',
            role: 3,
            level: 4,
            affix: true,
            isShow: 0,
            authClassify: "userGroup"
        },
        children: [
            {
                path: 'index',
                component: () => import('@/views/userGroup/index.vue'),
                name: 'userGroup',
                meta: {
                    title: '用户组管理',
                    classify: 'userGroup',
                    icon: 'userGroup',
                    table: 'user_group',
                    role: 3,
                    level: 4,
                    affix: true,
                    isShow: 1,
                    authClassify: "userGroup"
                }
            },
            {
                path: 'create/:id',
                component: () => import('@/views/userGroup/create.vue'),
                name: 'groupCreate',
                meta: {
                    title: '用户组创建',
                    classify: 'userGroup',
                    icon: 'userGroup',
                    table: 'user_group',
                    role: 3,
                    level: 4,
                    affix: true,
                    isShow: 1,
                    authClassify: "userGroup"
                }
            },
            {
                path: 'editShareAuth/:id',
                component: () => import('@/views/userGroup/editShareAuth.vue'),
                name: 'editShareAuthGroup',
                meta: {
                    title: '编辑组的贡献文件夹',
                    classify: 'userGroup',
                    icon: 'userGroup',
                    table: 'user_group',
                    role: 3,
                    level: 4,
                    affix: true,
                    isShow: 1,
                    authClassify: "userGroup"
                }
            }
        ]
    },
    {
        path: '/user',
        component: platform,
        redirect: '/user/index',
        name: "user",
        meta: {
            title: '用户管理',
            classify: 'user',
            icon: 'user',
            table: 'user',
            role: 3,
            level: 4,
            affix: true,
            isShow: 1,
            authClassify: "user"
        },
        children: [
            {
                path: 'index',
                component: () => import('@/views/user/index'),
                name: 'userIndex',
                meta: {
                    title: '用户管理',
                    classify: 'user',
                    icon: 'user',
                    table: 'user',
                    role: 3,
                    level: 4,
                    affix: true,
                    isShow: 1,
                    authClassify: "user"
                }
            },
            {
                path: 'create/:id',
                component: () => import('@/views/user/create'),
                name: 'userCreate',
                meta: {
                    title: '用户创建',
                    classify: 'user',
                    icon: 'user',
                    table: 'user',
                    role: 3,
                    level: 4,
                    affix: true,
                    isShow: 1,
                    authClassify: "user"
                }
            },
            {
                path: 'editShareAuth/:id',
                component: () => import('@/views/user/editShareAuth.vue'),
                name: 'editShareAuthUser',
                meta: {
                    title: '编辑用户的共享文件夹',
                    classify: 'user',
                    icon: 'user',
                    table: 'user',
                    role: 3,
                    level: 4,
                    affix: true,
                    isShow: 1,
                    authClassify: "user"
                }
            }
        ]
    },
])

const toolsRoutes = filterByKey([
    {
        path: '/import',
        component: platform,
        redirect: '/import/index',
        name: "import",
        meta: {title: '导入数据', classify: 'import', icon: 'import', affix: true, level: 2, role: 2, isShow: 0},
        children: filterByKey([
            {
                path: 'index',
                component: () => import('@/views/import/index'),
                name: 'importIndex',
                meta: {title: '导入数据', classify: 'import', icon: 'import', affix: true, level: 2, role: 2, isShow: 1}
            },
            {
                path: 'table',
                component: () => import('@/views/import/table'),
                name: 'importTable',
                meta: {title: '导入数据', classify: 'import', icon: 'import', affix: true, level: 2, role: 2, isShow: 1}
            }
        ])
    },
    {
        path: '/sysTools',
        component: platform,
        redirect: '/sysTools/index',
        name: "sysTools",
        meta: {title: '系统工具', classify: 'sysTools', icon: 'sysTools', affix: true, level: 2, role: 2, isShow: 0},
        children: filterByKey([
            {
                path: 'index',
                component: () => import('@/views/sysTools/index'),
                name: 'importIndex',
                meta: {title: '导入数据', classify: 'import', icon: 'import', affix: true, level: 2, role: 2, isShow: 1}
            },
        ])
    },
])

const systemRoutes = filterByKey([
    {
        path: '/boss',
        component: platform,
        redirect: '/boss/index',
        name: "boss",
        meta: {title: '管理员', classify: 'boss', icon: 'boss', affix: true, level: 3, role: 2, isShow: 1},
        children: filterByKey([
            {
                path: 'index',
                component: () => import('@/views/boss/index'),
                name: 'bossIndex',
                meta: {title: '管理员', classify: 'boss', icon: 'boss', affix: true, level: 3, role: 2, isShow: 1}
            },
            {
                path: 'detail/:id',
                component: () => import('@/views/boss/detail'),
                name: 'bossDetail',
                meta: {title: '管理员详情', classify: 'boss', icon: 'boss', affix: true, level: 3, role: 2, isShow: 1}
            }
        ])
    },
    {
        path: '/auth',
        component: platform,
        redirect: '/auth/index/0',
        name: "auth",
        meta: {title: '授权管理', classify: 'auth', icon: 'auth', affix: true, level: 3, role: 2, isShow: 1},
        children: filterByKey([
            {
                path: 'index/:id',
                component: () => import('@/views/auth/index'),
                name: 'authIndex',
                meta: {title: '授权管理', classify: 'auth', icon: 'auth', affix: true, level: 3, role: 2, isShow: 1}
            },
            {
                path: 'options',
                component: () => import('@/views/auth/options'),
                name: 'authOptions',
                meta: {title: '分类选项管理', classify: 'auth', icon: 'auth', affix: true, level: 3, role: 2, isShow: 1}
            }
        ])
    },
    {
        path: '/system',
        component: platform,
        redirect: '/system/index',
        name: "system",
        meta: {title: '系统配置', classify: 'system', icon: 'system', affix: true, level: 3, role: 2, isShow: 1},
        children: filterByKey([
            {
                path: 'index',
                component: () => import('@/views/system/index'),
                name: 'systemIndex',
                meta: {title: '系统配置', classify: 'system', icon: 'system', affix: true, level: 3, role: 2, isShow: 1}
            },
            {
                path: 'edit/wxShare',
                component: () => import('@/views/system/edit/wxShare'),
                name: 'systemWxShare',
                meta: {title: '微信分享配置', classify: 'system', icon: 'system', affix: true, level: 3, role: 2, isShow: 1}
            },
        ])
    },
    {
        path: '/dbBlast',
        component: platform,
        redirect: '/dbBlast/index',
        name: "dbBlast",
        meta: {title: '数据库比对', classify: 'dbBlast', icon: 'dbBlast', affix: true, level: 2, role: 2, isShow: 1},
        children: filterByKey([
            {
                path: 'index',
                component: () => import('@/views/dbBlast/index'),
                name: 'dbBlastIndex',
                meta: {title: '数据库比对', classify: 'dbBlast', icon: 'dbBlast', affix: true, level: 2, role: 2, isShow: 1}
            },
        ])
    },
])

const appLicenceRoutes = filterByKey([
    {
        path: '/licence',
        component: platform,
        redirect: '/licence/index',
        name: "licence",
        meta: {title: 'App授权', classify: 'licence', icon: 'licence', affix: true, level: 3, role: 2, isShow: 1},
        children: filterByKey([
            {
                path: 'index',
                component: () => import('@/views/licence/index'),
                name: 'licenceIndex',
                meta: {title: 'App授权', classify: 'licence', icon: 'licence', affix: true, level: 3, role: 2, isShow: 1, authClassify: "licence"}
            },
            {
                path: 'create/:id',
                component: () => import('@/views/licence/create'),
                name: 'licenceCreate',
                meta: {
                    title: '授权App创建',
                    classify: 'licence',
                    icon: 'licence',
                    table: 'app_licence',
                    role: 3,
                    level: 4,
                    affix: true,
                    isShow: 1,
                    authClassify: "licence"
                }
            },
        ])
    },
])

const constantRoutes = [
    ...openRoutes,
    ...toolsRoutes1,
    ...userRoutes,
    ...toolsRoutes,
    ...appLicenceRoutes,
    ...systemRoutes
];

const router = createRouter({
    history: createWebHashHistory(),
    routes: constantRoutes
});

//过滤 isShow == 0
function filterByKey(arrayObj) {
    if (!arrayObj.length) {
        return []
    }
    return arrayObj.filter((item) => {
        return item["meta"]["isShow"] - 0 === 1
    })
}

export {
    toolsRoutes1,
    userRoutes,
    toolsRoutes,
    appLicenceRoutes,
    systemRoutes,
    constantRoutes,
    router
}