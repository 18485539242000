/*!
 * @description: 工具方法 v0.01
 * @author: https://www.51xuediannao.com/
 * @update: 2020-02-28 18:02
 */

let util = {
    isFunction: function (obj) {
        return Object.prototype.toString.call(obj) === "[object Function]"
    },
    isArray: function (obj) {
        //return object && typeof object==='object' && Array == object.constructor;
        return Object.prototype.toString.call(obj) === "[object Array]";
    },
    isObject: function (obj) {
        return Object.prototype.toString.call(obj) === "[object Object]";
    },
    //是纯粹的对象 下面的extend用到
    isPlainObject: function (obj) {
        if (obj && Object.prototype.toString.call(obj) === '[object Object]' && obj.constructor === Object && !hasOwnProperty.call(obj, 'constructor')) {
            let key
            return key === undefined || hasOwnProperty.call(obj, key)
        }
        return false
    },
    //extend（jquery 3.1.1），使用和jq一样 util.extend({},{}) 或者 util.extend(true,{},{})
    extend: function () {
        let options, name, src, copy, copyIsArray, clone,
            target = arguments[0] || {},
            i = 1,
            length = arguments.length,
            deep = false;

        // Handle a deep copy situation
        if (typeof target === "boolean") {
            deep = target;

            // Skip the boolean and the target
            target = arguments[i] || {};
            i++;
        }

        // Handle case when target is a string or something (possible in deep copy)
        if (typeof target !== "object" && !this.isFunction(target)) {
            target = {};
        }

        // Extend jQuery itself if only one argument is passed
        if (i === length) {
            target = this;
            i--;
        }

        for (; i < length; i++) {

            // Only deal with non-null/undefined values
            if ((options = arguments[i]) != null) {

                // Extend the base object
                for (name in options) {
                    src = target[name];
                    copy = options[name];

                    // Prevent never-ending loop
                    if (target === copy) {
                        continue;
                    }

                    // Recurse if we're merging plain objects or arrays
                    if (deep && copy && (this.isPlainObject(copy) ||
                        (copyIsArray = this.isArray(copy)))) {

                        if (copyIsArray) {
                            copyIsArray = false;
                            clone = src && this.isArray(src) ? src : [];

                        } else {
                            clone = src && this.isPlainObject(src) ? src : {};
                        }

                        // Never move original objects, clone them
                        target[name] = this.extend(deep, clone, copy);

                        // Don't bring in undefined values
                    } else if (copy !== undefined) {
                        target[name] = copy;
                    }
                }
            }
        }

        // Return the modified object
        return target;
    },
}

util.arrayObjFilterByKey = function (arrayObj, key, value) {
    let index = arrayObj.findIndex((item) => {
        return item[key] === value
    })
    if (index === -1) {
        return arrayObj
    }

    arrayObj.splice(index, 1);
    return arrayObj
}

//数组去除重复元素
util.arrayUnique = function(arr) {
    return Array.from(new Set(arr));
}

util._postParams = function (obj) {
    let params = new URLSearchParams();
    for (let key in obj) {
        params.append(key, obj[key]);
    }
    return params;
};

//动态插入js文件  util._loadJs("css/base.js",callback,[["attr1","xx"],["attr2","yy"]])
util._loadJs = function (filename, callback, attrArr) {
    let fileRef = document.createElement('script');         //创建标签
    fileRef.type = "text/javascript";   //定义属性type的值为text/javascript
    fileRef.src = filename;              //文件的地址
    if (attrArr) {
        let attrLen = attrArr.length;
        for (let i = 0; i < attrLen; i++) {
            fileRef.setAttribute(attrArr[i][0], attrArr[i][1]);
        }
    }
    if (callback && typeof callback === "function") {
        if (fileRef.readyState) {
            fileRef.onreadystatechange = function () {
                if (fileRef.readyState === "loaded" || fileRef.readyState === "complete") {
                    fileRef.onreadystatechange = null;
                    callback();
                    document.getElementsByTagName("head")[0].removeChild(this);
                }
            };
        } else {
            fileRef.onload = function () {
                callback();
                document.getElementsByTagName("head")[0].removeChild(this);
            };
        }
    }
    document.getElementsByTagName("head")[0].appendChild(fileRef);
    return this
};

/**
 * 校验只要是数字（包含正负整数，0以及正负浮点数）就返回true
 **/
util._isNumber = function(val) {
    return typeof val === 'number'
}

//浏览器版本判断 示例：util.Browser().chrome  //  $lr().Browser().ver
util._browser = function () {
    let obj = {};
    let browserName = navigator.userAgent.toLowerCase();
    obj.ver = (browserName.match(/.+(?:rv|it|ra|ie)[/: ]([\d.]+)/) || [0, '0'])[1];
    obj.msie = /msie/i.test(browserName) && !/opera/.test(browserName);
    obj.firefox = /firefox/i.test(browserName);
    obj.chrome = /chrome/i.test(browserName) && /webkit/i.test(browserName) && /mozilla/i.test(browserName);
    obj.opera = /opera/i.test(browserName);
    obj.safari = /webkit/i.test(browserName) && !(/chrome/i.test(browserName) && /webkit/i.test(browserName) && /mozilla/i.test(browserName));
    return obj;
};

util._isMobile = function () {
    return navigator.userAgent.match(/(iPhone|iPod|Android|ios)/i) ? 1 : 0;
};

util._isWx = function () {
    let ua = navigator.userAgent.toLowerCase();
    return ua.match(/MicroMessenger/i) == "micromessenger";
};

util._guestInfo = function () {
    let browser = util.Browser();
    let browserVer = browser.ver;
    let agent = navigator.userAgent.toLowerCase();

    //将版本字符转化成浮点型
    let parseVersion = function (label) {
        let exp = new RegExp(label + '/([^\\s\\_\\-]+)');
        label = (agent.match(exp) || [])[1];
        return label ? label.replace(/(?!^\d+\.)(\b\d+)./g, '$1') : false
    };

    let result = {
        browser: function () {
            if (browser.msie) {
                return 'IE ' + browserVer;
            } else if (browser.firefox) {
                return 'firefox ' + browserVer;
            } else if (browser.chrome) {
                return 'chrome ' + browserVer;
            } else if (browser.opera) {
                return 'opera ' + browserVer;
            } else if (browser.safari) {
                return 'safari ' + browserVer;
            }
        }(),
        //获取当前操作系统
        os: function () {
            if (/windows/.test(agent)) {
                return 'windows';
            } else if (/linux/.test(agent)) {
                return 'linux';
            } else if (/|iphone|ipod|ipad|ios/.test(agent)) {
                return 'ios';
            } else if (/android/.test(agent)) {
                return 'android';
            }
        }()

        //获取微信版本，不在微信WebWiew则返回falase
        , weixin: parseVersion('micromessenger')

        //获取你的App版本，不在App WebWiew则返回falase。记得把myapp改成你的app特殊标识
        , myapp: parseVersion('myapp')
    };

    //result.android = /android/.test(agent); //是否安卓
    //result.ios = result.os === 'ios'; //是否IOS

    result.screenSize = [window.screen.width, window.screen.height];
    result.documentSize = [document.documentElement.clientWidth, document.documentElement.clientHeight];
    result.url = window.location.href;
    result.sourceUrl = document.referrer;
    result.sourceUrlJson = (result.sourceUrl.split("?")[1]) ? this.url2Json(result.sourceUrl.split("?")[1]) : null;

    return result;
};

//四舍五入 保留几位小数 js 的 toFixed 有精度问题，比如 8.74556 保留两位会是 8.74
//最后一位 >=5 时候， type=1 四舍五入， type=2 舍掉后面的不入  type=3 最后一位均进一
util._toFixed = function (number_value, digits = 2, type = 1) {
    let _number_value = number_value - 0;
    if (isNaN(_number_value)) {
        //console.error("Error:n not is Number")
        return false
    }

    let temp_array = _number_value.toString().split(".")
    console.log("temp_array", temp_array)
    if (temp_array.length === 1) {
        return number_value
    }

    if (temp_array[1].length <= digits) {
        return number_value
    }

    if (type === 2) {
        let digitsMap = { "1": 0.05, "2": 0.005, "3": 0.0005, "4": 0.0005 }
        _number_value = _number_value - digitsMap[digits]
    }

    if (type === 3) {
        let digitsMap = { "1": 0.05, "2": 0.005, "3": 0.0005, "4": 0.0005 }
        _number_value = _number_value + digitsMap[digits]
    }

    let _array = [1];
    for (let i = 0; i < digits; i++) {
        _array.push(0);
    }
    let _auxiliary = _array.join('') - 0; //两位 就是 100 三位就是 1000

    let accMul = function(arg1, arg2) {
        let m = 0,
            s1 = arg1.toString(),
            s2 = arg2.toString();
        try {
            m += s1.split(".")[1].length
        } catch (e) {
            console.log("accMul-catch",e)
        }
        try {
            m += s2.split(".")[1].length
        } catch (e) {
            console.log("accMul-catch",e)
        }
        return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m)
    }

    return (Math.round(accMul(_number_value, _auxiliary)) / _auxiliary).toFixed(digits)
}

util._ToBase64 = function (value) {
    return window.btoa(unescape(encodeURIComponent(value)))
}

util.trim = function(str){ //删除左右两端的空格
    return str.replace(/(^\s*)|(\s*$)/g, "");
}
util.ltrim = function(str){ //删除左边的空格
    return str.replace(/(^\s*)/g,"");
}
util.rtrim = function(str){ //删除右边的空格
    return str.replace(/(\s*$)/g,"");
}

util.calcDistance = function (lonA, latA, lonB, latB) {
    let earthR = 6371000;
    let x = Math.cos(latA * Math.PI / 180.) * Math.cos(latB * Math.PI / 180.) * Math.cos((lonA - lonB) * Math.PI / 180);
    let y = Math.sin(latA * Math.PI / 180.) * Math.sin(latB * Math.PI / 180.);
    let s = x + y;
    if (s > 1) s = 1;
    if (s < -1) s = -1;
    let alpha = Math.acos(s);
    return alpha * earthR;
}

util.getRandomNumber = function (minNum, maxNum) {
    switch (arguments.length) {
        case 1:
            return parseInt(Math.random() * minNum + 1, 10);
        case 2:
            return parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10);
        default:
            return 0;
    }
}

/*日期处理工具*/
util.date = {
    /**
     * 日期格式化
     * format 格式应为 "YYYY-MM-DD hh:mm:ss S q" 这样的组合
     * 可以传入 指定日期，如："2018/07/8 18:45:13"
     */
    format: function (format, time) {
        let now = time ? new Date(time) : new Date();
        let o = {
            "M+": now.getMonth() + 1,     //month
            "D+": now.getDate(),        //day
            "h+": now.getHours(),       //hour
            "m+": now.getMinutes(),     //minute
            "s+": now.getSeconds(),     //second
            "q+": Math.floor((now.getMonth() + 3) / 3), //quarter
            "S": now.getMilliseconds() //millisecond
        };

        if (/(Y+)/.test(format)) {
            format = format.replace(RegExp.$1, (now.getFullYear() + "").substr(4 - RegExp.$1.length));
        }

        for (let k in o) {
            if (new RegExp("(" + k + ")").test(format)) {
                format = format.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length));
            }
        }
        return format;
    },
    //获取年月日  可以传dates 往前+ 或 往后- 得到最终日期
    getDate: function (dates) {
        let dd = new Date();
        dd.setDate(dd.getDate() + dates);

        let y = dd.getFullYear();
        let m = dd.getMonth() + 1 < 10 ? "0" + (dd.getMonth() + 1) : dd.getMonth() + 1;
        let d = dd.getDate() < 10 ? "0" + (dd.getDate()) : dd.getDate();
        return y + "-" + m + "-" + d;
    },
    //本周一与当前日期相差的天数  monday
    getMonday: function () {
        let d = new Date();
        //周
        let day = d.getDay();
        return (day != 0) ? day - 1 : 6; // 本周一与当前日期相差的天数  monday
    },
    getYearPlus: function (date = "2012/01/1", year = 1) {
        let d1 = new Date(date);
        let d2 = new Date(d1);
        d2.setFullYear(d2.getFullYear() + year);
        d2.setDate(d2.getDate() - 1);
        //d2.toLocaleString()  //2031/5/29 下午11:09:53
        return this.format('YYYY-MM-DD hh:mm:ss', d2);
    },
    dayDiff(time, twoTime) {
        time = time.replace(new RegExp("-", "gm"), "/");
        let timeMiao = new Date(time).getTime()
        let year = new Date(time).getFullYear()
        let month = new Date(time).getMonth() + 1;
        let date = new Date(time).getDate();
        let thatYear = ""
        let thatTimeMiao = ""
        if (twoTime) {
            twoTime = twoTime.replace(new RegExp("-", "gm"), "/");
            thatYear = new Date(twoTime).getFullYear();
            thatTimeMiao = new Date(twoTime).getTime()
        } else {
            thatYear = new Date().getFullYear();
            thatTimeMiao = new Date().getTime();
        }
        let chaTime = thatTimeMiao - timeMiao;
        let day = parseInt(chaTime / 86400000)
        let yu = chaTime % 86400000
        let hour = parseInt(yu / 3600000)
        let yuH = yu % 3600000
        let min = parseInt(yuH / 60000)
        let yuM = yuH % 60000
        let sec = parseInt(yuM / 1000)
        let yYear = 0
        let yDay = 0;
        for (let x = 0; x <= thatYear - year; x++) {
            let fYear = year + x
            let fTime = new Date('' + fYear + '/' + month + '/' + date + '').getTime();
            let fDay = parseInt((fTime - timeMiao) / 86400000)
            if (fDay > 0 && fDay <= day) {
                yYear = yYear + 1;
                yDay = day - fDay
            }
        }
        return {yYear: yYear, yDay: yDay, day: day, hour: hour, minute: min, second: sec}
    }
}

/**
 * 日期格式化
 * format 格式应为 "YYYY-MM-DD hh:mm:ss S q" 这样的组合
 * 可以传入 指定日期，如："2018/07/8 18:45:13"
 */
util.formatDate = function (format, time) {
    let now = time ? new Date(time) : new Date();
    let o = {
        "M+": now.getMonth() + 1,     //month
        "D+": now.getDate(),        //day
        "h+": now.getHours(),       //hour
        "m+": now.getMinutes(),     //minute
        "s+": now.getSeconds(),     //second
        "q+": Math.floor((now.getMonth() + 3) / 3), //quarter
        "S": now.getMilliseconds() //millisecond
    };

    if (/(Y+)/.test(format)) {
        format = format.replace(RegExp.$1, (now.getFullYear() + "").substr(4 - RegExp.$1.length));
    }

    for (let k in o) {
        if (new RegExp("(" + k + ")").test(format)) {
            format = format.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length));
        }
    }
    return format;
}

// 'systemConfig'
util.getLocalStorage = function (key, defaultValue="") {
    let valueString = localStorage.getItem(key);
    if (!valueString) {
        return defaultValue
    }
    return JSON.parse(valueString)
}

//绑定对象数据到 model {}  data {}
util.bindObj = function (model, data) {
    for (let key in model) {
        model[key] = data[key] || model[key] || ""
    }

    return model
}

//设置动作的 loading 初始状态  arrayObj源数组[{},{}...]  动作名称 "delete,edit"
util.setLoadingAction = function (arrayObj, loadingActions, status = false) {
    let loadingActionArray = util.isArray(loadingActions) ? loadingActions : loadingActions.split(",")
    for (let item of arrayObj) {
        for (let i = 0; i < loadingActionArray.length; i++) {
            item[loadingActionArray[i] + 'Loading'] = status
        }
    }
}

//更新数据 针对列表数据 [{},{},...]  newData是一个对象{}，或者是多个对象[{},{},...]  action="update" || delete
util.updateArrayObj = function (arrayObj, newData,action="update") {
    let _newData = util.isArray(newData) ? newData : [newData]
    for (let i = 0; i < _newData.length; i++) {
        let item = _newData[i]
        let index = arrayObj.findIndex(value => {
            return value.Id === item.Id
        })

        if (index > -1) {
            if(action==="update"){
                arrayObj[i] = util.extend({}, arrayObj[i], item)
            }else{
                arrayObj.splice(index,1);
            }
        }
    }
    return arrayObj
}

//多个数组的全排列组合  tools.doCombination();
util.doCombination = function(arr) {
    let count = arr.length - 1; //数组长度(从0开始)
    let tmp = [];
    let totalArr = [];// 总数组

    //js 没有静态数据，为了避免和外部数据混淆，需要使用闭包的形式
    function doCombinationCallback(arr, curr_index) {
        for(const value of arr[curr_index]) {
            tmp[curr_index] = value;//以curr_index为索引，加入数组
            //当前循环下标小于数组总长度，则需要继续调用方法
            if(curr_index < count) {
                doCombinationCallback(arr, curr_index + 1);//继续调用
            }else{
                totalArr.push(tmp);//(直接给push进去，push进去的不是值，而是值的地址)
            }
            //js  对象都是 地址引用(引用关系)，每次都需要重新初始化，否则 totalArr的数据都会是最后一次的 tmp 数据；
            let oldTmp = tmp;
            tmp = [];
            for(const index of oldTmp) {
                tmp.push(index);
            }
        }
        return totalArr;
    }

    return doCombinationCallback(arr, 0);//从第一个开始
};

//处理分类 级别
util.getSonsTree = function(arrayObj,iniPid=0){
    console.log('arrayObj',arrayObj)
    let _array = arrayObj
    let arrayTemp = []
    let fn = function (id,lev) {
        for (let i=0;i<_array.length;i++){
            if(id===_array[i]['pid']){
                _array[i]['lev'] = lev
                arrayTemp.push(_array[i])
                fn(_array[i]['Id'],lev+1)
            }
        }
    }
    fn(iniPid,0)
    console.log('arrayTemp',arrayTemp)
    return arrayTemp
}

util.getCurPid = function(data){
    let userInfo = JSON.parse(localStorage.getItem("userInfo"))
    if(!userInfo.team_sn){
        return "0"
    }
    let obj = data.find((value)=>{
        return value.sn===userInfo.team_sn
    })

    if(!obj){
        return "0"
    }
    return  obj.pid||"0"
}

util.formatSku = function(sku, keys) {
    let teamArray = [];
    let array = this.stringToArray(sku, "--!--", "@")
    let keys_array = keys.split("-##-")
    for (let i = 0; i < keys_array.length; i++) {
        teamArray.push(`${array[i][0]}: ${keys_array[i]}`)
    }
    return teamArray.join("、")
}

//暂时只支持二维数组
util.stringToArray = function (stringArray,separator1="@",separator2="#"){
    let arr = []
    let arr1 = stringArray.split(separator1)
    for(let i=0;i<arr1.length;i++){
        let item = arr1[i].split(separator2)
        arr.push([item[0],item[1]])
    }

    return arr
}

export {util}