/**
 * @author: https://www.51xuediannao.com/
 * @update: 2021-09-23 12:02
 */
const _CONFIG = Object.assign({
    "debug": true,
    "host": process.env.VUE_APP_HOST,
    "apiPath": process.env.VUE_APP_API,
    "api":{
        "www":process.env.VUE_APP_API+"/www",
        "boss":process.env.VUE_APP_API+"/boss",
        "apiThirdPath":"http://123.57.104.245:10221",
    },
    "cdn": "https://lrcms2022.oss-cn-hangzhou.aliyuncs.com",
    "rootPath": "",
    "siteName": "云友网络",
    "systemFullName": "郑州云友网络科技有限公司",
    "shortName": "云友网络",
    "slogan":"云友网络",
    "column": [],
    "goodsDetailType":"images", //editor images
}, {});

export {_CONFIG}